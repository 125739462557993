<template>

    <teleport to="head">
        <title>{{ metaData.title }}</title>
        <meta property="og:title" :content="metaData.ogTitle">
        <meta name="description" :content="metaData.description">
        <meta property="og:description" :content="metaData.ogDescription">
        <meta property="og:image" :content="metaData.ogImage">
    </teleport>

    <div class="wrapper container-centered">

        <div class="top-sidebar" v-if="currentPage !== 'privacy' && currentPage !== 'imprint'">
            <div class="row">
                <div class="gr-24">
                    <div class="top-sidebar-content fixed gr-9 gr-16@small gr-24@mobile">
                        <img src="./images/header-sidebar.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="placeholder" v-else></div>

        <!--the-header :class="{ 'visible-menu': visibleHeaderMenu}" :isLoading="isLoading"></the-header-->
        <the-header></the-header>

        <main class="main" :class="{'no-sidebar': currentPage == 'privacy' || currentPage == 'imprint'}">
            <div class="content-container">
                <router-view :products="products" :videoIsLoading="videoIsLoading" :videoIsPlaying="videoIsPlaying" :displaySignupForm="displaySignupForm"></router-view>
            </div>
        </main>

        <the-footer></the-footer>

        <teleport to="#app">

            <div class="popup-view" v-show="errorMessage" @click="closeErrorMessage">
                <pop-up class="popup">
                    <div class="popup-text">
                        {{ errorMessage }}
                    </div>
                    <base-button class="popup-button button" type="button" @click="closeErrorMessage">{{ popupButton }}</base-button>
                </pop-up>
            </div>

            <div class="popup-view" v-show="notice" @click="noticeRef? handleNotice() : closeNotice()">
                <pop-up class="popup">
                    <div class="popup-text">
                        {{ notice }}
                    </div>
                    <base-button class="popup-button button" type="button" @click="closeNotice">{{ popupButton}}</base-button>
                </pop-up>
            </div>

            <div class="popup-view" v-show="confirmationNotice">
                <pop-up class="popup">
                    <div class="popup-text">
                        {{ confirmationNotice }}
                    </div>
                    <div class="confirmation-button-container">
                        <base-button class="confirmation-button button" type="button" color="#54990852" @click="handleConfirmation">{{ confirmationButton }}</base-button>
                        <base-button class="confirmation-button button" type="button" @click="closeConfirmationNotice">{{ declineButton }}</base-button>
                    </div>
                </pop-up>
            </div>

        </teleport>
    </div>
</template>

<script>
    import './css/app.css';
    import storage from './data/storage.json';
    import axios from 'axios';

    export default {
        name: 'App',
        provide() {
            return {
                setMetaData: this.setMetaData,
                //setLoading: this.setLoading,
                setOrderLoading: this.setOrderLoading,
                handleOrderProcess: this.handleOrderProcess,
                sendNewsletterForm: this.sendNewsletterForm,
                setError: this.setError,
                setNotice: this.setNotice,
                setConfirmationNotice: this.setConfirmationNotice,
                setVideoLoading: this.setVideoLoading,
                toggleVideo: this.toggleVideo,
                toggleSignupForm: this.toggleSignupForm,
            }
        },
        data() {
            return {
                metaData: {
                    title: 'Starkes Mindset * Fitnessstudio für den Kopf',
                    ogTitle: 'Starkes Mindset * Fitnessstudio für den Kopf',
                    description: 'Die 9+1 Tage Challenge für High Performer, Berater:innen & Coaches, die sich mehr Klarheit & Fokus wünschen und ihre Energie auf Erfolg ausrichten wollen.',
                    ogDescription: 'Die 9+1 Tage Challenge für High Performer, Berater:innen & Coaches, die sich mehr Klarheit & Fokus wünschen und ihre Energie auf Erfolg ausrichten wollen.',
                    ogImage: 'https://starkes-mindset.com/img/og-starkes-mindset.jpg'
                },
                //isLoading: false,
                orderIsLoading: false,
                //visibleHeaderMenu: false,
                errorMessage: null,
                popupButton: '',
                notice: null,
                noticeRef: false,
                noticeData: null,
                confirmationNotice: null,
                confirmationRef: '',
                confirmationButton: '',
                declineButton: '',
                confirmationData: null,
                videoIsLoading: {},
                videoIsPlaying: {},
                displaySignupForm: true,
                products: storage.products,
            }
        },
        watch: {
            $route (to, from) {
                if(to.name == from.name && to.hash == '#signup') {
                    return;

                } else {
                    this.stopVideos();
                }
            }
        },
        methods: {
            setMetaData(meta) {
                for(let [key, value] of Object.entries(meta)) {
                    this.metaData[key] = value;
                }
            },
            /*setLoading(state) {
                this.isLoading = state;
            },*/
            setOrderLoading(state) {
                this.orderIsLoading = state;
            },
            setError(error, button = 'OK') {
                this.errorMessage = error;
                this.popupButton = button;
            },
            setNotice(notice, button = 'OK', ref = false, data = null) {
                this.notice = notice;
                this.popupButton = button;

                this.noticeRef = ref;
                this.noticeData = data;
            },
            handleNotice() {
                this.closeNotice();

                if (this.noticeRef === 'multipleNotices') {
                    this.setNotice(this.noticeData);
                }
            },
            setConfirmationNotice(text, ref, confButton = 'Ja', declButton = 'Nein', data = null) {
                this.confirmationNotice = text;
                this.confirmationRef = ref;
                this.confirmationButton = confButton;
                this.declineButton = declButton;
                this.confirmationData = data;
            },
            handleConfirmation() {
                /*if(this.confirmationRef === 'logout') {
                    this.logout();
                    this.closeForm();
                }

                if(this.confirmationRef === 'profile') {
                    this.cancelProfileChanges = true;
                }

                if(this.confirmationRef === 'newsletter') {
                    this.cancelNewsletter = true;
                }

                if(this.confirmationRef === 'account') {
                    this.deleteAccount(this.user);
                }

                if(this.confirmationRef === 'otherAccount') {
                    this.deleteAccount(this.confirmationData);
                }

                if (this.confirmationRef === 'order') {
                    window.location.href = this.confirmationData;
                }*/

                this.closeConfirmationNotice();
            },
            async closeErrorMessage() {
                this.errorMessage = null;
                await this.$store.dispatch('auth/eraseErrorMessage');
            },
            closeNotice() {
                this.notice = null;
            },
            closeConfirmationNotice() {
                this.confirmationNotice = null;
            },
            setVideoLoading(option, slug) {
                if(!slug) {
                    this.videoIsLoading = option;
                } else {
                    this.videoIsLoading[slug] = option;
                }
            },
            toggleVideo(slug, ref) {
                if(this.videoIsPlaying[slug]) {
                    ref.pause();
                    this.videoIsPlaying[slug] = false;
                    this.setVideoLoading(false, slug);
                } else {
                    ref.play();
                    this.stopVideos();
                    this.videoIsPlaying[slug] = true;
                    this.setVideoLoading(true, slug);

                    //setTimeout(function() { this.setVideoLoading(true, id) }, 300);
                }
            },
            stopVideos() {
                this.videoIsPlaying = {};
                this.videoIsLoading = {};
            },
            toggleSignupForm(option) {
                this.displaySignupForm = option;
            },
            async handleOrderProcess(product, user) {
                let data = new FormData();

                data['token'] = 'A4GPqFTvGXEfrGsiWoBtqy';
                data['firstName'] = user.firstName;
                data['lastName'] = user.lastName;
                data['email'] = user.email;
                data['phone'] = user.phone;
                data['product'] = product;

                let url = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');

                //const response = await axios.post('http://localhost:8080/php/sendOrderForm.php', {
                const response = await axios.post(url + '/php/sendOrderForm.php', {
                    data
                });

                const responseData = await response.data;

                if (response.ok == false) {
                    const error = new Error(responseData.message || 'Benutzeranmeldung fehlgeschlagen.');
                    throw error;
                }
            },
            async sendNewsletterForm(email, user) {
                let data = new FormData();

                data['token'] = 'A4GPqFTvGXEfrGsiWoBtqy';
                data['email'] = email;
                data['newsletter'] = true;
                if (user) {
                  data['firstName'] = user.firstName;
                  data['lastName'] = user.lastName;
                }

                let url = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');

                //const response = await axios.post('http://localhost:8080/php/sendNewsletterForm.php', {
                const response = await axios.post(url + '/php/sendNewsletterForm.php', {
                    data
                })

                const responseData = await response.data;

                if (response.ok == false) {
                    const error = new Error(responseData.message || 'Newsletter-Anmeldung fehlgeschlagen.');
                    throw error;
                }
            },
        },
        computed: {
            currentPage() {
                return this.$route.name;
            },
        }
    }
</script>

<style>
</style>
